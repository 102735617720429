.delete {
  .section {
      //padding-top: 30px;
  }
  .preview-image {
    height: 300px;
    margin-right: 20px;
    margin-top: 20px;
  }

  .preview-image-array {
    height: 150px;
    margin-right: 20px;
    margin-top: 20px;
  }

  .title {
    padding-top: 45px;
  }

  .more-info-title{
    margin-top: 35px;
  }

  .contact-us-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app-screen-delete-account{
    .vertical-center{
      display: flex;
      justify-content: center;
      align-items: center;

      p, ol {
        margin: auto;
        position: absolute;
        top: 40%;
        font-weight: 300;
        font-size: 1.2em;
      }
    }
  }
}
