.preview {
  .section {
      padding-top: 30px;
  }
  .preview-image {
    height: 300px;
    margin-right: 20px;
    margin-top: 20px;
  }

  .preview-image-array {
    height: 150px;
    margin-right: 20px;
    margin-top: 20px;
  }

  .title {
    padding-top: 45px;
  }

  .more-info-title{
    margin-top: 35px;
  }
}
