img{
    max-width: 100%;
    border-radius: 1px;
}
.img-raised{
    box-shadow: $box-shadow-raised;
}

.img-badge{
    max-height: 100px !important;
    border-radius: 1px;
}

.img-ios-badge{
    height: 70px !important;
}

.img-ios-badge-small{
    height: 40px !important;
}

.img-app-badge-small{
    margin-top: 10px;
    height: 60px !important;
}

.img-app-screen{
    max-height: 500px !important;
}